import { Link } from '@mui/material';
import { React, useState } from 'react';
import { useLogin } from '../../../hooks/useLogin';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const {login,error,isLoading}=useLogin()
  const navigate = useNavigate(); 

      //Validation
      if (email.length > 40) {
        alert("Email address should not exceed 40 characters.");
        return;
      }
      if (password.length > 30) {
        alert("Password should not exceed 30 characters.");
        return;
      }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await login(email, password);
  console.log(response)
  navigate('/');
      if (response) {
        
      } else {
        // ... (handle server error) ...
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  }

  return (
    <div>
      <div style={{paddingTop: 67, fontSize: 40, textAlign: 'center'}}>Login to Your Account</div>
      <form onSubmit={handleSubmit}
        className="w-25 mx-auto border border-2 shadow-sm p-3  rounded"
        style={{ marginTop: 70}}
      >
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          {/* <div id="emailHelp" className="form-text">
            We'll never share your email with anyone else.
          </div> */}
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </div>
        <button disabled={isLoading}
          className="mx-auto d-block mt-3"
          variant="contained"
          style={{
            borderRadius: 30,
            border: 'none',
            paddingInline: 25,
            paddingTop: 7,
            paddingBottom: 7,
            backgroundColor: 'black',
            color: 'white',
            fontSize: 15,
          }}
        >
          Login
        </button>
        {error && <div className='error'>{error}</div>}
      </form>

      {/* sign up   */}
      <div className="text-center mt-3" style={{padding: "20px"}}>
        <Link href="/signup" style={{background: "cornsilk", borderRadius: "10px", padding: "10px"}}>Don't have an account? Sign Up</Link>
      </div>
      <p style={{textAlign: "center", paddingTop: "20px", color: "grey"}}>Facing an issue while accessing your account? Mail us at <a href='mailto:contact@textmerger.com'>contact@textmerger.com</a>.</p>
    </div>
  );
};

export default Login;
