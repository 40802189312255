import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import RemoveIcon from '@mui/icons-material/Remove';
import ReplayIcon from '@mui/icons-material/Replay';
import { Button, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import TextEditor from '../../components/TextEditor/TextEditor';
import Footer from '../../components/Footer/Footer';
import { articleType, size } from '../../constants';
import styles from './Home.module.css';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import pdfToText from 'react-pdftotext';

const Home = () => {
  const [inputValues, setInputValues] = useState({
    input1: '',
    input2: '',
    input3: '',
  });

  const [showInput3, setShowInput3] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [combinedData, setCombinedData] = useState('');

  const handleInputChange = (event, fieldName) => {
    if (isEditable) {
      let value = event.target.value;
      if (value.length > 1000) { 
        value = value.slice(0, 1000); 
      }
      setInputValues({
        ...inputValues,
        [fieldName]: value,
      });
    }
  };

const [selectedFormat, setSelectedFormat] = useState(articleType[0].value);
const [selectedLength, setSelectedLength] = useState(size[0].value); // State for length
const [addHeading, setAddHeading] = useState(false);          // State for add heading
const [makeLists, setMakeLists] = useState(false);          // State for make lists 

  const handleFormatChange = (event) => {
    setSelectedFormat(event.target.value);
    if (event.target.value !== "Normal") { 
      setAddHeading(false);
      setMakeLists(false);
      setSelectedLength(size[0].value); // Set length back to default
    }
  };

  const handleLengthChange = (event) => {
    setSelectedLength(event.target.value);
  };
  
  const handleAddHeadingChange = (event) => {
    setAddHeading(event.target.checked); 
  };
  
  const handleMakeListsChange = (event) => {
    setMakeLists(event.target.checked);
  };

  const extractTextFromPDF = (event, fieldName) => {
    const fileBlob = event.target.files[0];

    const maxFileSizeInBytes = 2 * 1024 * 1024;

    if (fileBlob.size > maxFileSizeInBytes) {
      alert(`File size too large. Maximum allowed size is ${maxFileSizeInBytes / (1024 * 1024)} MB.`);
      return; // Prevent further processing
    }
  
    const file = new File([fileBlob], fileBlob.name, { type: fileBlob.type });
  
    pdfToText(file)
      .then(text => {
        // const words = text.split(/\s+/); // Split the text into words
        // const truncatedText = words.slice(0, 150).join(' '); // Take the first 1000 words
        const truncatedText = text.length > 1000 ? text.slice(0, 1000) : text;
  
        setInputValues({
          ...inputValues,
          [fieldName]: truncatedText,  
        });
      })
      .catch(error => console.error("Failed to extract text from pdf", error));
  };

  const combineInputValues = async () => {
    if (inputValues.input1==='' && inputValues.input2==='' && inputValues.input3==='') {
      return alert('Please enter text')
    }
    if (isEditable) {
      const inputData = {
        inputText1: inputValues.input1 || '',
        inputText2: inputValues.input2 || '',
        inputText3: showInput3 ? inputValues.input3 || '' : '',
        textFormat: selectedFormat,
        textLength: selectedLength,         
        addHeading: addHeading,      
        makeLists: makeLists
      };

      setIsLoading(true); // Show the loader

      console.log("Input Data:", inputData);
      try {
        const response = await fetch('https://api.textcombiner.com/merge/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(inputData),
        });

        const data = await response.json();
        if (response.ok) {
          setCombinedData(data.generated_text); // Set generated text in state
        } else {
          // Handle errors if needed
          console.error('Error:', data);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }

      setIsEditable(false); // Disable editability after submission
    }

    setIsButtonClicked(true); // Set the button clicked state
    setIsEditable(false); // Disable editability after submission
  };

  const toggleInput3 = () => {
    setShowInput3(!showInput3);
  };

  const addAgain = () => {
    setIsEditable(true);
    setIsButtonClicked(false)
    setInputValues({
      input1: '',
      input2: '',
      input3: '',
    });
  };

  return (
    <div>
      <div className="container mt-4 ">
        <div className={styles.heading}>Text Merger</div>
        <div className={styles.description}>
          For Content Creators and Bloggers
        </div>
        <p style={{textAlign: "center", paddingTop: "10px", fontSize: "16px", marginBottom: "4px"}}>AI Text Merger is an advanced paraphrasing tool that helps rewrite your article by merging different texts.</p>
        <p style={{textAlign: "center", borderRadius: "5px", padding: "8px", color: "green", background: "honeydew", fontSize: "small"}}>You can also use a single input for paraphrased text that is not detectable as AI-generated.</p>
        <div className={styles.description} style={{fontSize: "small", paddingTop: "15px"}}>
          Developed by <a href='https://ateeq.pk'>M. Ateeq</a>
        </div>
        
        <div className="d-flex flex-column flex-md-row flex-column-reverse border border-2 shadow p-4 mb-5 bg-body rounded my-4">
          <div className="d-none d-md-block d-flex flex-row flex-wrap flex-md-column gap-4 col col-md-2 p-4 ">
            <div className="d-flex gap-1 align-items-center w-100">
              <TextField
                    id="outlined-select-post-type"
                    select
                    labelId="demo-simple-select-label"
                    value={selectedFormat}
                    onChange={handleFormatChange}
                    size="small"
                    className="w-100"
                    label="Article Format" // Persistent label
              >
                {articleType.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    // defaultValue={articleType[3].value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Tooltip title="To Get Quality Output Please Select the Right Article Format" placement="top-start">
                <InfoIcon fontSize="14px" />
              </Tooltip>
            </div>

            <div className="d-flex gap-1 align-items-center w-100 mt-3">
              <TextField
                id="outlined-select-Length"
                select
                label="Select Length"
                size="small"
                className="w-100"
                value={selectedLength} // Bind to state
                onChange={handleLengthChange} // Add change handler
                disabled={selectedFormat !== "Normal"}
              >
                {size.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Tooltip title="We Recommend Default Length for High Quality Output." placement="top-start">
                <InfoIcon fontSize="14px" />
              </Tooltip>
            </div>

            <div className="d-flex flex-row flex-md-column">
              <FormControlLabel
                control={<Checkbox checked={addHeading} onChange={handleAddHeadingChange} />}
                label="Add Heading"
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 20 },
                  '& .MuiFormControlLabel-label': { fontSize: 14 },
                }}
                disabled={selectedFormat !== "Normal"}
              />
              <FormControlLabel
                control={<Checkbox checked={makeLists} onChange={handleMakeListsChange} />}
                label="Add List"
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 20 },
                  '& .MuiFormControlLabel-label': { fontSize: 14 },
                }}
                disabled={selectedFormat !== "Normal"}
              />
            </div>
          </div>

          <div className="accordion d-md-none" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Advanced Options
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className=" d-flex flex-row flex-wrap flex-md-column gap-4 col col-md-2 p-4 ">
                    <div className="d-flex gap-1 align-items-center w-100">
                      <TextField
                        id="outlined-select-post-type"
                        select
                        label="Select Type"
                        defaultValue={articleType[3].value}
                        // helperText="Please select your Post type"
                        size="small"
                        className="w-100"
                      >
                        {articleType.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Tooltip title="Post type" placement="top-start">
                        <InfoIcon fontSize="10px" />
                      </Tooltip>
                    </div>

                    <div className="d-flex gap-1 align-items-center w-100 mt-3">
                      <TextField
                        id="outlined-select-Length"
                        select
                        label="Select Length"
                        defaultValue={size[1].value}
                        // helperText="Please select your Length"
                        size="small"
                        className="w-100"
                      >
                        {size.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Tooltip title="Select Your Action" placement="top-start">
                        <InfoIcon fontSize="10px" />
                      </Tooltip>
                    </div>

                    <div className="d-flex flex-row flex-md-column">
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Add Heading"
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 20 },
                          '& .MuiFormControlLabel-label': { fontSize: 14 },
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Add List"
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 20 },
                          '& .MuiFormControlLabel-label': { fontSize: 14 },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Input fields */}
          
          <div className="col col-md-10  p-3">
            <div>
              <div className="d-flex flex-column flex-md-row align-items-center">
                <TextField
                  label="Text 1"
                  multiline
                  rows={4}
                  value={inputValues.input1}
                  className="w-100"
                  // className="col-12 col-md-12"
                  onChange={(event) => handleInputChange(event, 'input1')}
                  disabled={!isEditable} // Disable input if not editable
                />
                <div className="d-none d-md-block d-lg-block hide-on-tablet">
                <Button 
                  component="label"
                  variant="contained"
                    style={{ 
                    borderRadius: 30,
                    paddingInline: 25, 
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: 'black', 
                    fontSize: 15,
                    marginBottom: 20,
                    marginLeft: 10,
                    height: 50,
                    }}
                  startIcon={<CloudUploadIcon />}
                  >
                  PDF
                  <input 
                    type="file" 
                    accept="application/pdf" 
                    hidden 
                    onChange={(event) => extractTextFromPDF(event, 'input1')} // Replace 'input1' with the correct field name
                  />
                </Button>
                </div>
              </div>
              {/* <div className={styles.wordCount}>
                Word Limit :{' '}
                {
                  inputValues.input1
                    .trim()
                    .split(/\s+/)
                    .filter((word) => word !== '').length
                }
                /1000
              </div> */}
               <div className={styles.wordCount}>
                  Character Limit: {inputValues.input1.length} / 1000
                </div>
            </div>

            <div>
              <div className="d-flex align-items-center">
                <TextField
                  label="Text 2"
                  multiline
                  rows={4}
                  value={inputValues.input2}
                  className="w-100 mt-4"
                  onChange={(event) => handleInputChange(event, 'input2')}
                  disabled={!isEditable} // Disable input if not editable
                />
                <div className="d-none d-md-block d-lg-block">
                  <Button
                    variant="contained"
                    component = "label"
                    style={{
                      borderRadius: 30,
                      paddingInline: 25,
                      paddingTop: 10,
                      paddingBottom: 10,
                      backgroundColor: 'black',
                      fontSize: 15,
                      marginBottom: 20,
                      marginLeft: 10,
                      height: 50,
                    }}
                    startIcon={<CloudUploadIcon />}
                  >
                    PDF
                    <input 
                      type="file" 
                      accept="application/pdf" 
                      hidden // Hide the file input
                      onChange={(event) => extractTextFromPDF(event, 'input2')} 
                    /> 
                  </Button>
                </div>
              </div>
              {/* <div className={styles.wordCount}>
                Word Limit :{' '}
                {
                  inputValues.input2
                    .trim()
                    .split(/\s+/)
                    .filter((word) => word !== '').length
                }
                /1000
              </div> */}
                <div className={styles.wordCount}>
                  Character Limit: {inputValues.input2.length} / 1000
                </div>

            </div>

            {showInput3 && (
              <div>
                <div className="d-flex align-items-center">
                  <TextField
                    label="Text 3"
                    multiline
                    rows={4}
                    value={inputValues.input3}
                    className="w-100 mt-4"
                    onChange={(event) => handleInputChange(event, 'input3')}
                    disabled={!isEditable} // Disable input if not editable
                  />
                  <div className="d-none d-md-block d-lg-block">
                    <Button
                      variant="contained"
                      component = "label"
                      style={{
                        borderRadius: 30,
                        paddingInline: 25,
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: 'black',
                        fontSize: 15,
                        marginBottom: 20,
                        marginLeft: 10,
                        height: 50,
                      }}
                      startIcon={<CloudUploadIcon />}
                    >
                      PDF
                      <input 
                      type="file" 
                      accept="application/pdf" 
                      hidden // Hide the file input
                      onChange={(event) => extractTextFromPDF(event, 'input3')} 
                    /> 
                    </Button>
                  </div>
                </div>
                {/* <div className={styles.wordCount}>
                  Word Limit :{' '}
                  {
                    inputValues.input3
                      .trim()
                      .split(/\s+/)
                      .filter((word) => word !== '').length
                  }
                  /1000
                </div> */}
                <div className={styles.wordCount}>
                    Character Limit: {inputValues.input3.length} / 1000
                </div>
              </div>
            )}

            <div className="d-flex flex-column flex-md-row mt-4 mt-md-0 gap-3 justify-content-center ">
              <Button
                variant="contained"
                onClick={combineInputValues}
                style={{
                  borderRadius: 30,
                  paddingInline: 25,
                  paddingTop: 10,
                  paddingBottom: 10,
                  backgroundColor: 'black',
                  fontSize: 15,
                  opacity: isButtonClicked ? 0.25 : 1,
                }}
              >
                Submit
              </Button>

              <Button
                variant="contained"
                onClick={toggleInput3}
                startIcon={showInput3 ? <RemoveIcon /> : <AddIcon />}
                style={{
                  borderRadius: 30,
                  paddingInline: 25,
                  paddingTop: 10,
                  paddingBottom: 10,
                  backgroundColor: 'black',
                  fontSize: 15,
                }}
              >
                {showInput3 ? 'remove' : 'Add More'}
              </Button>

              <Button
                variant="contained"
                style={{
                  borderRadius: 30,
                  paddingInline: 25,
                  paddingTop: 10,
                  paddingBottom: 10,
                  backgroundColor: 'black',
                  fontSize: 15,
                }}
                startIcon={<ReplayIcon />}
                onClick={addAgain}
              >
                Add Again
              </Button>
            </div>
          </div>

        </div>
        {/* Input fields */}
        {isLoading && (
      
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}> 
      <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
      </div>
        
      )}
          {<TextEditor combinedData={combinedData} inputs={inputValues} />}
          <div style={{paddingTop: 150}} ></div>
      </div>
      <Footer />
    </div>

  );
};

export default Home;
