import { Button, TextField } from '@mui/material';
import {createTheme } from '@mui/material/styles';
import React, { useEffect, useState} from 'react';
import { useHistoryContext } from "../../hooks/useHistoryContext"
import { useAuthContext } from '../../hooks/useAuthContext'
import { url} from '../../config/config';
import EasyMDE from 'easymde';
import 'easymde/dist/easymde.min.css';

const TextEditor = React.memo(({ combinedData ,inputs}) => {
  const [data, setData] = useState();
  const [headingLevel] = useState('header-six'); // Default heading level is h1
  const [keyword, setkeyword] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [seoAnalysis, setSeoAnalysis] = useState(null); // State for SEO analysis
  const [plagiarismPercentage, setPlagiarismPercentage] = useState(null);
  const [previousCombinedData, setPreviousCombinedData] = useState(''); 

  const { dispatch } = useHistoryContext()
  const { user } = useAuthContext()
  

  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [previousKeyword] = useState('');

  useEffect(() => {
    setData({
      blocks: [
        {
          key: 'heading',
          text: ' ',
          type: headingLevel,
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: 'content',
          text: combinedData,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    });
    setIsSaveDisabled(false);
  }, [combinedData, headingLevel]);

  const handleCheckSEO = async () => {
    if (keyword.length < 4) {
      alert("Add a keyword of at least 4 characters.");
      return;
    }
    if (keyword.length > 60) {
      alert("Keyword is Longer then 60 characters.");
      return;
    }
    if (keyword === previousKeyword) {
      alert("You already checked this keyword. Change your keyword to re-analyze.");
      return;
    }
  
    const seoData = {
      generated_text: combinedData,
      keyword: keyword,
    };
  
    try {
      const response = await fetch('https://api.textcombiner.com/seo/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(seoData),
      });
  
      const data = await response.json();
      if (response.ok) {
        setSeoAnalysis(data);          // <-- Correctly set seoAnalysis
        setPreviousCombinedData(combinedData);
      } else {
        console.error('SEO analysis error:', data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handelCheckPlagiarism = async () => {
    const inputValues = Object.values(inputs).filter(value => value.trim() !== '');
    const combinedInput = inputValues.join(' ');
    const output = combinedData; 
    const plagiarismData = {
      text1: combinedInput, 
      text2: output,
    };
  
    if (combinedData === previousCombinedData) {
      alert("Nothing to Check or Already Checked, Use New Submission.");
      return;
    }

    if (!combinedData) {
      alert("Output Text is not Available");
      return;
    }
    if (!combinedInput.trim()) {
      alert("Combined Inputs are not Available");
      return;
    }

    if (!output.trim()) {
      alert("Output is not Available");
      return;
    }



    try {
      const response = await fetch('https://api.textcombiner.com/plagiarism/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(plagiarismData),
      });
      const data = await response.json();
      if (response.ok) {
        setPlagiarismPercentage(data.plagiarism_percentage);
        setPreviousCombinedData(combinedData);
      } else {
        console.error('Plagiarism check error:', data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const save = async (e) => {
    e.preventDefault()

    if (!user) {
      alert("You need to be logged in to save!");
      return
    }

    if (!combinedData) {
      alert("No text to save!");
      return;
    }
    const inputValues = Object.values(inputs);

    if(inputValues[2]===''){
      inputValues.pop()
    }
  const output = data.blocks.map((block) => block.text).join('\n');

    const history = {input:inputValues, output}

    const response = await fetch(`${url}/history`, {
      method: 'POST',
      body: JSON.stringify(history),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()

    if (!response.ok) {
      console.log(json.error)
    }
    if (response.ok) {

      setIsSaveDisabled(true);
      dispatch({type: 'CREATE_WORKOUT', payload: json})
      setShowSuccessMessage(true);  // Show success message
      setTimeout(() => {
        setShowSuccessMessage(false); // Hide after 2 seconds
      }, 2000); 
    }
  }

  const myTheme = createTheme();
  Object.assign(myTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          display: 'inline-block',
          flexDirection: 'row',
          alignItems: 'center', // Align items in the center of the row
          marginTop: 5,
          width: '100%',
        },
        editor: {
          borderColor: 'lightgray',
          borderWidth: '2px',
          borderStyle: 'solid',
          paddingTop: '50px',
          paddingBottom: '50px',
          padding: '20px',
          borderRadius: '10px',
        },
      },
    },
  });

  const textAreaId = 'my-text-area';
  useEffect(() => {
    const easyMDE = new EasyMDE({ 
      element: document.getElementById(textAreaId),
      
      initialValue: data?.blocks[1].text // Pass the initial text here
    });
    return () => easyMDE.toTextArea(); // This will revert the textarea back when the component unmounts
  }, [data]);
  return (
    <div className="d-flex flex-column flex-md-row mb-2">
      <div className="col col-md-9 border border-1  shadow-sm rounded-start">

<textarea id={textAreaId} />
        {/* </div> */}
        {showSuccessMessage && ( 
            <div 
            className="alert alert-success" 
            role="alert"
            style={{ 
              maxWidth: '300px',  // Adjust width as needed
              margin: '10px auto', // Center horizontally and add some margin
              textAlign: 'center'  // Center the text 
            }}
          >
              Saved successfully!
            </div>
          )}
        <div className="d-flex justify-content-center gap-2 my-3">
        
          <Button
            variant="contained"
            onClick={save}
            disabled={isSaveDisabled}
            style={{
              borderRadius: 30,
              paddingInline: 25,
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: 'black',
              fontSize: 15,
            }}
          >
            Save
          </Button>
        </div>
        
      </div>
      <div className="col col-md-3 border border-1  shadow-sm rounded-end border-start-0 p-3">
        <div className="my-3">
          <Button
            variant="contained"
            style={{
              borderRadius: 30,
              paddingInline: 25,
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: 'black',
              fontSize: 15,
              marginBottom: 20,
              marginLeft: 10,
            }}
            onClick={handelCheckPlagiarism}
          >
            
            Check Plagiarism
          </Button>
          {plagiarismPercentage !== null && (
        <div> {/* Add a div to wrap the text */}
          <p style={{fontSize: 40, textAlign: 'center'}}>{plagiarismPercentage}% </p>
        </div>
      )}
        </div>
        <div className="mt-4 pt-3 border-top">
          <Button
            variant="contained"
            style={{
              borderRadius: 30,
              paddingInline: 25,
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: 'black',
              fontSize: 15,
              marginBottom: 20,
              marginLeft: 50,
            }}
            
            onClick={handleCheckSEO} 
          >
            Check SEO
          </Button>

          {keyword.length < 4 && (
            <div style={{ fontSize: 12 }} className=" text-danger">
              Add keyword of at least 4 character
            </div>
          )}
        </div>

        <TextField
          label="Add Keyword"
          multiline
          value={keyword}
          className="w-100"
          onChange={(e) => setkeyword(e.target.value)}
        />

        
        {seoAnalysis && ( 
        
        <div>
          <div className="shadow mt-3 fw-bold p-3  bg-body rounded">
          <div>Keyword Density : {seoAnalysis.density}</div>
          </div>
          <div className="shadow mt-3 fw-bold p-3  bg-body rounded">
          <div>No of Keywords : {seoAnalysis.occurrences}</div>
          </div>
          <div className="shadow mt-3 fw-bold p-3  bg-body rounded">
          <div>Keyword Ratio : {seoAnalysis.density_feedback}</div>
          </div>
          <div className="shadow mt-3 fw-bold p-3  bg-body rounded">
          <div>Keyword in Intro : {seoAnalysis.intro_feedback}</div>
          </div> 
        </div>
      
    )}

      </div>
    </div>
  );
});

export default TextEditor;