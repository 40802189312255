export const articleType = [
  {
    value: 'Normal',
    label: 'Normal',
  },
  {
    value: 'BlogPost',
    label: 'BlogPost',
  },
  {
    value: 'News',
    label: 'News',
  },
  {
    value: 'Summary',
    label: 'Summary',
  },
];
export const size = [
  {
    value: 'Default',
    label: 'Default',
  },
  {
    value: 'Medium',
    label: 'Medium',
  },
  {
    value: 'Short',
    label: 'Short',
  },
];
